import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import { AddressBook } from '@interface/addressBook.interface';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';

const URL = `${API_URL}/admin`;

export const fetchWallets = createAsyncThunk(
  `${SLICE_NAME}/fetchWallets`,
  async (): Promise<AddressBook[]> => {
    const result = await axios(`${URL}/wallet-addresses`);
    return result.data;
  },
);

export const addAddress = createAsyncThunk(
  `${SLICE_NAME}/addAddress`,
  async (data: {
    address: string;
    privateKey: string;
    currency: string;
  }): Promise<AddressBook[]> => {
    const result = await axios.post(`${URL}/add-address`, data);
    return result.data;
  },
);

export const drainWallets = createAsyncThunk(
  `${SLICE_NAME}/drainWallets`,
  async (): Promise<{ message: string }> => {
    const result = await axios.post(`${URL}/drain-wallets`, {});
    return result.data;
  },
);

export const drainStorage = createAsyncThunk(
  `${SLICE_NAME}/drainStorage`,
  async (data: {
    amount?: number;
    currency: CryptoCurrency;
  }): Promise<{ message: string }> => {
    const result = await axios.post(`${URL}/drain-storage`, data);
    return result.data;
  },
);
