import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { purple } from '@mui/material/colors';
import { AddCircle } from '@mui/icons-material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  drainStorage,
  drainWallets,
  fetchWallets,
} from '@action/wallet.action';
import { selectWallets } from '@selector/wallet.selector';
import AddressDialog from '@page/Wallets/AddressDialog';
import LoadingButton from '@component/LoadingButton';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';

const Wallets: React.FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectWallets);
  const [openAddWalletDialog, setOpenAddWalletDialog] = useState(false);
  const [isDrainingWallets, setIsDrainingWallets] = useState(false);
  const [isDrainingStorage, setIsDrainingStorage] = useState(false);

  useEffect(() => {
    dispatch(fetchWallets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openRow = (address: string) => {
    window.open(`https://tronscan.org/#/address/${address}`, '_blank');
  };

  const onClick = () => {
    setOpenAddWalletDialog(true);
  };

  const onDrainWallets = async () => {
    setIsDrainingWallets(true);
    await dispatch(drainWallets());
    setIsDrainingWallets(false);
  };

  const onDrainUsdtStorage = async () => {
    setIsDrainingStorage(true);
    await dispatch(drainStorage({ currency: CryptoCurrency.USDT }));
    setIsDrainingStorage(false);
  };

  const onDrainUsdcStorage = async () => {
    setIsDrainingStorage(true);
    await dispatch(drainStorage({ currency: CryptoCurrency.USDC }));
    setIsDrainingStorage(false);
  };

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="h4">Wallets ({data?.length || 0})</Typography>

        <Stack direction="row" gap={1}>
          <LoadingButton
            variant="contained"
            onClick={onDrainUsdtStorage}
            isLoading={isDrainingStorage}
          >
            Drain USDT Storage
          </LoadingButton>

          <LoadingButton
            variant="contained"
            onClick={onDrainUsdcStorage}
            isLoading={isDrainingStorage}
          >
            Drain USDC Storage
          </LoadingButton>

          <LoadingButton
            onClick={onDrainWallets}
            isLoading={isDrainingWallets}
            variant="contained"
          >
            Drain App Wallets
          </LoadingButton>

          <IconButton onClick={onClick} color={'primary'}>
            <AddCircle />
          </IconButton>
        </Stack>
      </Toolbar>

      <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
        {data?.length && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Currency</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Assigned</TableCell>
                  <TableCell>Last Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map(
                  ({
                    id,
                    updatedAt,
                    address,
                    appTransactionId,
                    currency,
                    balance,
                  }) => (
                    <TableRow
                      key={id}
                      onClick={() => openRow(address)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { bgcolor: purple[100] },
                      }}
                    >
                      <TableCell>{currency}</TableCell>
                      <TableCell>{address}</TableCell>
                      <TableCell>{balance}</TableCell>
                      <TableCell>{appTransactionId ? '✅' : ''}</TableCell>
                      <TableCell>{dayjs(updatedAt).fromNow()}</TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <AddressDialog
        open={openAddWalletDialog}
        onClose={() => {
          setOpenAddWalletDialog(false);
        }}
      />
    </>
  );
};

export default Wallets;
